import * as React from "react";
import Layout from "@components/layout";
import Intro from "@components/intro";
import Albums from "@components/albums";
import PhotoViewer from "@components/photo-viewer";
import { Helmet } from "react-helmet";
import { useThemeState } from "@context/theme";
import PageTransition from "gatsby-plugin-page-transitions";
import { graphql } from "gatsby";

// styles
const pageStyles = {
  color: "#232129",
  padding: 0,
  margin: 0,
};

// markup
const IndexPage = () => {
  const { viewerOpen, openViewer } = useThemeState();

  const handleOpen = () => {
    openViewer("2019", 2);
  };
  return (
    <Layout>
      <Helmet>
        <title>#REELMETICONS</title>
      </Helmet>
      <main style={pageStyles}>
        <Intro />
        <Albums />
        {viewerOpen && <PhotoViewer />}
      </main>
    </Layout>
  );
};

export default IndexPage;
