import React from "react";
import imageSrc from "@images/instagram-hero.svg";
import notchSrc from "@images/ui-notch.svg";
import introInstructionsSrc from "@images/ui-instructions@2x.png";
import * as styles from "./index.module.scss";

const Intro = () => {
  return (
    <section className={styles.intro}>
      <div className={styles.introBg} />
      <div className={styles.introTitle}>
        <img draggable={false} className={styles.hero} src={imageSrc} />
        <h1>#REELMETICONS</h1>
        <img
          draggable={false}
          className={styles.instructions}
          src={introInstructionsSrc}
          style={{ pointerEvents: "none" }}
        />
        {/* <ol> */}
        {/* <li>Browser Galleries</li>
          <li>
            Select and Save Image to Device
            <br />
            (if needed crop image to your
            <br />
            preferred ratio)
          </li>
          <li>Launch Instagram Reels</li> */}
        {/* </ol> */}
      </div>
      <div className={styles.introScrollDown}>
        <strong>Scroll Down</strong>
        <img
          style={{ pointerEvents: "none" }}
          draggable={false}
          src={notchSrc}
        />
      </div>
    </section>
  );
};

export default Intro;
