import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Item from "./atoms/item";
import Hacks from "./atoms/hacks";
import * as styles from "./index.module.scss";

const Albums = () => {
  const [showExtended, setShowExtended] = useState(false);
  const { allPrismicAlbum } = useStaticQuery(graphql`
    query AlbumQuery {
      allPrismicAlbum(sort: { fields: first_publication_date, order: ASC }) {
        edges {
          node {
            uid
            data {
              title {
                text
              }
              outbound_link {
                url
              }
              items {
                text {
                  text
                }
                image {
                  url
                  thumbnails {
                    Square {
                      url
                    }
                  }
                  localFile {
                    square: childImageSharp {
                      resize(width: 400, height: 400, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { edges: albums } = allPrismicAlbum;
  return (
    <section className={styles.albums}>
      <nav>
        <ul className={styles.albumsList}>
          {albums &&
            albums.map(({ node }) => {
              const { uid } = node;
              return (
                <Item
                  node={node}
                  key={uid}
                  showExtended={showExtended}
                  setShowExtended={setShowExtended}
                />
              );
            })}
          <Hacks />
        </ul>
      </nav>
    </section>
  );
};

export default Albums;
