import React, { useRef, useEffect, useState } from "react";
import backArrowSrc from "@images/ui-back-arrow.svg";
import downloadButtonSrc from "@images/ui-download-button.svg";
import { useStaticQuery, graphql } from "gatsby";
import { useThemeState } from "@context/theme";
import prevButtonSrc from "@images/ui-prev.svg";
import nextButtonSrc from "@images/ui-next.svg";
import Img from "gatsby-image";
import { get } from "lodash";
import Slider from "react-slick";
import * as styles from "./index.module.scss";

const PhotoViewer = () => {
  const { closeViewer, viewerActiveAlbum, viewerActiveIndex } = useThemeState();
  const [viewerIdx, setViewerIdx] = useState(viewerActiveIndex);
  const slider = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // slidesToShow: viewerActiveIndex,
    prevArrow: <img src={prevButtonSrc} />,
    nextArrow: <img src={nextButtonSrc} />,
    afterChange: (current, next) => {
      setViewerIdx(current);
    },
  };
  //   useEffect(() => {
  //     console.log(",", document.querySelector("body").classList);

  //     // if (!viewerActiveAlbum) {
  //     document.querySelector("body").classList.add("lock");

  //     return () => {
  //       document.querySelector("body").classList.remove("lock");
  //     };
  //   }, [viewerActiveAlbum]);
  const { allPrismicAlbum } = useStaticQuery(graphql`
    query ViewerQuery {
      allPrismicAlbum(sort: { fields: first_publication_date, order: ASC }) {
        edges {
          node {
            uid
            data {
              title {
                text
              }
              outbound_link {
                url
              }
              items {
                text {
                  text
                }
                image {
                  url
                  thumbnails {
                    Square {
                      url
                    }
                  }
                  localFile {
                    square: childImageSharp {
                      resize(width: 400, height: 400, cropFocus: CENTER) {
                        src
                      }
                    }
                    compressed: childImageSharp {
                      resize(width: 1000, quality: 90, jpegProgressive: true) {
                        src
                      }
                    }
                    hires: childImageSharp {
                      resize(width: 2000, quality: 90, jpegProgressive: true) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { node: currentAlbum } = allPrismicAlbum.edges.find(({ node }) => {
    return node.uid === viewerActiveAlbum;
  });

  const {
    data: { items = [] },
  } = currentAlbum;

  useEffect(() => {
    slider.current.slickGoTo(viewerActiveIndex);
  }, [viewerActiveIndex]);

  const downloadLink =
    items[viewerIdx || viewerActiveIndex]?.image.localFile.hires.resize.src ||
    null;
  const downloadFilename = downloadLink && downloadLink.split("/").pop();

  return (
    <div className={styles.photoViewer}>
      <div className={styles.photoViewerActions}>
        <button onClick={closeViewer}>
          <img src={backArrowSrc} />
          <label>Back</label>
        </button>
      </div>
      <div className={styles.photoViewerStage}>
        <Slider {...settings} ref={slider}>
          {items &&
            items.map(({ image }, idx) => {
              const imageSrc = get(
                image,
                "localFile.compressed.resize.src",
                false
              );
              console.log(imageSrc);
              return (
                <div className={styles.photoViewerSlide}>
                  <img key={idx} src={imageSrc} />
                </div>
              );
            })}
        </Slider>
      </div>
      <div className={styles.photoViewerDownload}>
        <a href={downloadLink} download={downloadFilename}>
          <label>Download</label>
          <img src={downloadButtonSrc} />
        </a>
      </div>
    </div>
  );
};

export default PhotoViewer;
