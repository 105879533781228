import React, { useState } from "react";
import hacksSrc from "@images/ui-hacks@2x.png";
import * as styles from "./../index.module.scss";

const Hacks = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <li className={styles.hacks}>
        <h2>
          <button onClick={toggleOpen}>IG Hacks</button>
        </h2>
        <div
          className={styles.hacksContent}
          style={{
            height: open ? "auto" : 0,
            paddingTop: open ? "22px" : 0,
            marginTop: open ? "12px" : 0,
            borderTop: open ? "1px solid #4D4D4D" : "1px solid transparent",
          }}
        >
          <img
            style={{ pointerEvents: "none" }}
            draggable={false}
            src={hacksSrc}
          />
        </div>
      </li>
    </>
  );
};

export default Hacks;
