import React, { useState } from "react";
import { Link } from "gatsby";
import { useThemeState } from "@context/theme";
import { get } from "lodash";
import * as styles from "./../index.module.scss";

const Item = ({ node, showExtended, setShowExtended = () => {} }) => {
  const [open, setOpen] = useState(false);
  const { data, uid } = node;
  const {
    title,
    outbound_link: { url: outbound_link_url },
    items = [],
  } = data;
  const { openViewer } = useThemeState();

  const toggleOpen = () => {
    setOpen(!open);
  };
  const toggleShowExtended = () => {
    setShowExtended(!showExtended);
  };

  const active = items.length > 1;

  const hide = [2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011];

  if (!showExtended && hide.indexOf(parseInt(title.text)) > -1) {
    return <div />;
  }

  console.log("outbound_link", node);
  return (
    <>
      <li className={open && active ? styles.active : ""}>
        <h2>
          <a target="_blank" href={outbound_link_url}>
            {title?.text}
          </a>
        </h2>
      </li>

      <ul
        className={styles.albumsListContents}
        style={{
          height: open && active ? "auto" : "0px",
          margin: open && active ? "40px 0 40px 0" : "0 0 10px 0",
        }}
      />
    </>
  );
};

export default Item;
